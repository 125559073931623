<template>
  <div class="container" v-loading="loading">
    <div class="col-xs-12 col-sm-12 col-md-12">
      <div class="post-title">
          {{item.title}}
      </div>

      <div>
        <span>发布时间：{{item.created_at}}</span> &nbsp;&nbsp;|&nbsp;&nbsp;
        <span>浏览：{{item.view}}</span>
      </div>
      
      <div class="row course-details">
        <div v-html="item.body"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'CourseDetails',
  props:['user'],
  data() {
    return {
      item: [],
      loading: true,
    }
  },
  created() {
    this.getDetails()
  },
  methods: {
    async getDetails() {
      const response = await axios.post('/api/web/book/post?id='+this.$route.query.id);
      const data = response.data.data
      this.item = data
      // console.log(this.item)
      this.loading = false
    },
  }
}
</script>
